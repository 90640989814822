.popover-wrapper {
  position: relative;

  .popover-body {
    position: absolute;
    display: block;
    z-index: 2;
    background-color: var(--black);
    color: var(--white);
    top: 50px;
    right: 0;
    border-radius: 8px;
    border: 1px solid var(--gray-600);
  }
}
