.dashboard-container {
  background-color: var(--black);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../../../public/assets/sphereDroneCaptureFiltered.png);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  .dashboard-area {
    max-width: 1264px;
    margin: 0 auto;
    min-width: 500px;
    padding: 0 var(--spacing-3);
    width: 100%;

    .dashboard-top-content {
      color: var(--white);
      display: flex;
      padding: var(--spacing-0) 0px 20px;
      justify-content: flex-start;
      flex-direction: column;

      .heading-container {
        display: flex;
        padding: var(--spacing-9) var(--spacing-1) 0;

        .help-cta {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: none;
          background: var(--black);
          border-radius: 40px;
          color: var(--white);
          height: 34px;
          padding: var(--spacing-1) var(--spacing-3);

          svg {
            margin-right: 5px;
          }

          span {
            font-size: var(--font-size-body-sm);
            color: var(--gray-300);
            letter-spacing: 0.05em;
          }

          svg {
            fill: var(--gray-200);
          }

          &.help-cta-hover {
            &:hover,
            &:focus-within {
              span {
                color: var(--gray-800);
              }
              svg {
                fill: var(--gray-800);
              }
              background: linear-gradient(250deg, var(--gray-100) 19%, var(--gray-200) 75%);
            }
          }
        }
      }
    }
  }
}
