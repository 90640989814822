@import '../../../../styles/variables.breakpoints.module.scss';

.filename-container {
  padding: 0 var(--spacing-1);
  flex: 1 1 110px;
  max-width: max-content;
  width: 110px;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    direction: rtl;
    white-space: nowrap;
  }
}
