@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--black-rgba), 0.8);
  backdrop-filter: blur(8px);
  overflow: auto;

  .icon-close-container {
    @include btn-reset-styles();
    position: absolute;
    top: 20px;
    right: 16px;

    img {
      width: 13px;
      height: 13px;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .modal-dialog {
    width: auto;
    height: 100%;
    display: flex;
    padding: 15px;
  }

  .modal-content {
    position: relative;
    padding: var(--spacing-3) var(--spacing-3);
    margin: auto;
    background-color: var(--gray-900);
    border-radius: var(--spacing-1);

    &.modal-content--narrow {
      width: 672px;

      .icon-close-container {
        z-index: 10000;
        top: 28px;
        cursor: pointer;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        transition: opacity 0.8s linear;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.modal-content--wide {
      //Resizes the Gallery modal contents while maintaining aspect ratio when the viewport gets smaller
      width: 134vh;
      max-width: 1280px;
      padding: 0;

      .icon-close-container {
        padding: 12px;
        z-index: 10000;
        cursor: pointer;
        border-radius: 50%;
        @include btnColor(light, hollow, false);
        top: 6px;
        right: 6px;
        width: 40px;
        height: 40px;
        transition: opacity 0.8s linear;

        img {
          width: 16px;
          height: 16px;

          @include breakpoint-down(sm) {
            width: 12px;
            height: 12px;
          }
        }

        @include breakpoint-down(sm) {
          width: 32px;
          height: 32px;
          padding: 10px;
        }
      }
    }

    // Assumes button is at bottom of modal
    button:last-child {
      @include breakpoint-down(sm) {
        // required to prevent iOS Chrome bottom bar from overlapping
        // do not change unless specifically required
        margin-bottom: var(--spacing-4);
      }
    }

    &.recipients-modal-content {
      padding: var(--spacing-4) var(--spacing-2) var(--spacing-2);

      button:last-child {
        @include breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }
    }
  }
}
