@mixin link($color: brand-primary, $text-decoration: false) {
  color: var(--#{$color});
  transition: var(--link-color-transition);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--link-color-#{$color}-hover);
  }

  @if $text-decoration {
    text-decoration: underline;
  }
}

@function btnGradient($fromColor, $toColor) {
  @return linear-gradient(250deg, $fromColor 19%, $toColor 75%);
}

@mixin btnColor($color: primary, $fill: solid, $disabled: false) {
  $bgColor: '';
  $bgColorHover: '';
  $borderColor: '';
  $boxShadowHover: '';
  $textColor: '';
  $textColorHover: '';

  @if $disabled {
    $bgColor: var(--gray-600);
    $bgColorHover: var(--gray-600);
    $borderColor: transparent;
    $textColor: var(--white);
    $textColorHover: var(--white);
  } @else if $color == dark {
    @if $fill == solid {
      $bgColor: btnGradient(var(--gray-900), var(--black));
      $bgColorHover: btnGradient(var(--black), var(--gray-900));
      $borderColor: transparent;
      $textColor: var(--white);
      $textColorHover: var(--white);
    } @else if $fill == hollow {
      $bgColor: var(--gray-600);
      $bgColorHover: btnGradient(var(--black), var(--gray-900));
      $borderColor: rgba(var(--black-rgba), 0.1);
      $textColor: var(--black);
      $textColorHover: var(--white);
    } @else if $fill == text {
      $bgColor: transparent;
      $bgColorHover: transparent;
      $borderColor: transparent;
      $boxShadowHover: drop-shadow(0px 0px 8px rgba(var(--black-rgba), 0.1));
      $textColor: var(--gray-900);
      $textColorHover: var(--gray-800);
    }
  } @else if $color == light {
    @if $fill == solid {
      $bgColor: btnGradient(var(--gray-200), var(--white));
      $bgColorHover: btnGradient(var(--white), var(--gray-200));
      $borderColor: transparent;
      $textColor: var(--black);
      $textColorHover: var(--black);
    } @else if $fill == hollow {
      $bgColor: transparent;
      $bgColorHover: btnGradient(var(--white), var(--gray-200));
      $borderColor: var(--white);
      $textColor: var(--white);
      $textColorHover: var(--black);
    } @else if $fill == text {
      $bgColor: transparent;
      $bgColorHover: transparent;
      $borderColor: transparent;
      $textColor: var(--white);
      $textColorHover: var(--gray-200);
    }
  } @else if $color == primary {
    @if $fill == solid {
      $bgColor: btnGradient(var(--brand-primary-300), var(--brand-primary));
      $bgColorHover: btnGradient(var(--brand-primary), var(--brand-primary-300));
      $borderColor: transparent;
      $textColor: var(--black);
      $textColorHover: var(--black);
    } @else if $fill == hollow {
      $bgColor: rgba(var(--black-rgba), 0.1);
      $bgColorHover: btnGradient(var(--brand-primary), var(--brand-primary-300));
      $borderColor: var(--brand-primary);
      $textColor: var(--white);
      $textColorHover: var(--black);
    } @else if $fill == text {
      $bgColor: transparent;
      $bgColorHover: transparent;
      $borderColor: transparent;
      $textColor: var(--brand-primary);
      $textColorHover: var(--brand-primary-300);
    } @else if $fill == square {
      $bgColor: linear-gradient(251.02deg, var(--turquoise-300) 19.17%, var(--turquoise-500) 75.2%);
      $bgColorHover: linear-gradient(247.63deg, var(--turquoise-500) 27.85%, var(--turquoise-300) 81.23%);
      $borderColor: transparent;
      $textColor: var(--gray-800);
      $textColorHover: var(--gray-800);
    }
  } @else if $color == secondary {
    @if $fill == solid {
      $bgColor: btnGradient(var(--brand-secondary-400), var(--brand-secondary));
      $bgColorHover: btnGradient(var(--brand-secondary), var(--brand-secondary-400));
      $borderColor: transparent;
      $textColor: var(--white);
      $textColorHover: var(--white);
    } @else if $fill == hollow {
      $bgColor: rgba(var(--black-rgba), 0.1);
      $bgColorHover: btnGradient(var(--brand-secondary), var(--brand-secondary-400));
      $borderColor: var(--brand-secondary);
      $textColor: var(--white);
      $textColorHover: var(--white);
    } @else if $fill == text {
      $bgColor: transparent;
      $bgColorHover: transparent;
      $borderColor: transparent;
      $textColor: var(--brand-secondary);
      $textColorHover: var(--brand-secondary-400);
    }
  }
  background: $bgColor;
  border-color: $borderColor;
  color: $textColor;

  &:hover {
    background: $bgColorHover;
    color: $textColorHover;
    filter: $boxShadowHover;
  }

  @if $fill != text and $fill != square {
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s linear;
      background: $bgColorHover;
      border-radius: 40px;
    }

    &:hover {
      box-shadow: var(--box-shadow-btn);

      &:before {
        opacity: 1;
      }
    }
  }
}

@mixin btnSize($size) {
  padding: 8px 24px;

  @if $size == xl {
    font-size: 24px;

    @include breakpoint-down(sm) {
      font-size: 18px;
    }
  } @else if $size == lg {
    font-size: 18px;

    @include breakpoint-down(sm) {
      font-size: 14px;
    }
  } @else if $size == md {
    font-size: 14px;
  } @else if $size == sm {
    font-size: 12px;
  } @else if $size == sxteen {
    font-size: 16px;
  }
}

@mixin btnFullWidth() {
  width: 100%;
  display: block;
  padding-right: 0;
  padding-left: 0;
}

@mixin btn(
  $size: md,
  $color: white,
  $fill: solid,
  $disabled: false,
  $custom-size: false,
  $width: 100%,
  $height: 100%,
  $mobile-height: 100%,
  $mobile-width: 100%,
  $border-radius: 40px
) {
  @if $fill == solid {
    border: 0;
  } @else {
    border: 1px solid transparent;
  }
  @include btnSize($size);
  @include btnColor($color, $fill, $disabled);
  position: relative;
  display: block;
  transition: var(--link-color-transition);
  cursor: pointer;
  font-weight: var(--font-weight-light);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: $border-radius;

  @if $custom-size {
    width: $mobile-width;
    height: $mobile-height;
    letter-spacing: 0.8px;

    @include breakpoint-up(md) {
      height: $height;
      width: $width;
      font-weight: var(--font-weight-extra-bold);
    }
  }
  &:hover,
  &:hover:after {
    transition: var(--link-color-transition);
  }

  @if $disabled == true {
    pointer-events: none;
  }
}

@mixin heading($size) {
  width: 100%;
  margin: 0;
  line-height: 1.1;

  @include breakpoint-up(md) {
    line-height: 1.2;
  }

  @if $size == display {
    font-size: var(--font-size-specialty-display-mobile);
    font-weight: var(--font-weight-ultra-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-specialty-display-desktop);
    }
  }

  @if $size == 1 {
    font-size: var(--font-size-h1-mobile);
    font-weight: var(--font-weight-ultra-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h1-desktop);
    }
  }

  @if $size == 2 {
    font-size: var(--font-size-h2-mobile);
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: 25px;
    margin-top: 30px;
    color: var(--gray-200);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h2-desktop);
      margin-bottom: 35px;
    }
  }

  @if $size == 3 {
    font-size: var(--font-size-h3-mobile);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 19px;
    margin-top: 45px;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h3-desktop);
    }
  }

  @if $size == 4 {
    font-size: var(--font-size-h4-mobile);
    font-weight: var(--font-weight-extra-bold);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h4-desktop);
    }
  }

  @if $size == 5 {
    font-size: var(--font-size-h5-mobile);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h5-desktop);
    }
  }

  @if $size == 6 {
    font-size: var(--font-size-h6-mobile);
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: 20px;
    text-transform: lowercase;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h6-desktop);
    }
  }

  @if $size == label {
    font-size: var(--font-size-specialty-label-mobile);
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: 20px;
    letter-spacing: 0.105em;
    text-transform: uppercase;

    @include breakpoint-up(md) {
      font-size: var(--font-size-specialty-label-desktop);
    }
  }

  @if $size == extra {
    font-size: var(--font-size-specialty-extra-mobile);
    font-weight: var(--font-weight-semi-light);

    @include breakpoint-up(md) {
      font-size: var(--font-size-specialty-extra-desktop);
    }
  }
}

@mixin body-copy($size: 'md', $isBold: false) {
  font-weight: var(--font-weight-medium);

  @if $isBold {
    font-weight: var(--font-weight-semi-bold);
  }
  @if $size == lg {
    font-size: var(--font-size-body-lg);
  } @else if $size == md {
    font-size: var(--font-size-body-md);
  } @else if $size == sm {
    font-size: var(--font-size-body-sm);
  } @else if $size == xsm {
    font-size: var(--font-size-body-xsm);
    font-weight: var(--font-weight-semi-bold);
  }
}

@mixin svg-btn($color: white) {
  height: var(--close-button-width-mobile);
  width: var(--close-button-width-mobile);
  max-height: var(--close-button-width-mobile);
  max-width: var(--close-button-width-mobile);
  margin: 0;

  @include breakpoint-up(lg) {
    height: var(--close-button-width-desktop);
    width: var(--close-button-width-desktop);
    max-height: var(--close-button-width-desktop);
    max-width: var(--close-button-width-desktop);
  }

  @if $color == white or $color == black or $color == gray-500 {
    @include link($color);
  }
}

@mixin arrow($direction, $size: 'md') {
  display: inline-block;
  border-style: solid;
  border-color: transparent transparent var(--white) transparent;

  @if $size == md {
    border-width: 0 8px 14px 8px;
  }

  @if $size == sm {
    border-width: 0 6px 8px 6px;
  }

  @if $direction == left {
    transform: rotate(-90deg);
  }

  @if $direction == right {
    transform: rotate(90deg);
  }

  @if $direction == up {
    transform: rotate(0deg);
  }

  @if $direction == down {
    transform: rotate(180deg);
  }
}

@mixin flex-row($column-gap: 48px) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: $column-gap;
}

@mixin flex-col() {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1 auto;
}

$margins: (
  '0': 0,
  '1': var(--spacing-1),
  '2': var(--spacing-2),
  '3': var(--spacing-3),
  '4': var(--spacing-4)
);

$margins_headings: (
  '0': 0,
  '1': var(--spacing-1),
  '2': var(--spacing-2)
);

@each $margin-level, $margin-value in $margins {
  .margin-top-#{$margin-level} {
    margin-top: $margin-value;
  }

  .margin-bottom-#{$margin-level} {
    margin-bottom: $margin-value;
  }
}

@mixin btn-reset-styles() {
  background-color: transparent;
  border: none;
}
