@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.box {
  // border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  margin-bottom: 24px;
  text-align: left;
  width: 100%;
  /* Gradients/Black 80 - 10 */
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%);
  /* Glass BG - no shadow */
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #474747;
  border-radius: 8px;
  table-layout: fixed;

  &.dropdown-open {
    position: relative;
    z-index: 1;
  }

  tr:first-child {
    th:first-child {
      //filename
      flex: 1 1 auto;
    }
    th:nth-child(2) {
      //"file size"
      flex: 0 0 auto;
      width: 108px;
    }
    th:nth-child(3) {
      //"date uploaded"
      flex: 0 0 auto;
      width: 149px;
    }
    th:nth-child(4) {
      //"uploaded by"
      flex: 0 0 auto;
      width: 216px;
    }
    th:last-child {
      //hamburger + preview buttons
      flex: 0 0 auto;
      width: 218px;

      @include breakpoint-down(sm) {
        flex: 1 1 0px;
      }
    }
  }

  .uploaded-by-initials-container {
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    div {
      max-width: 100%;
      overflow: hidden;
    }
  }

  tr {
    display: flex;
    position: relative;
    z-index: auto;

    td:first-child {
      flex: 1 1 356px;
      overflow: hidden;

      div[data-type='title'] {
        display: flex;
        flex: 1 1 auto;
        span {
          line-height: 32px;
          font-size: var(--font-size-body-md);
          display: inline-block;
          font-weight: var(--font-weight-light);
        }

        span[data-testid='experience-id'] {
          text-overflow: ellipsis;
          overflow: hidden;
          direction: rtl;
          white-space: nowrap;
        }

        span[data-testid='DRPGG'] {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    td:nth-child(2) {
      white-space: nowrap;
      flex: 0 0 auto;
      width: 108px;
    }

    td:nth-child(3) {
      white-space: nowrap;
      flex: 0 0 auto;
      width: 149px;
    }

    td:nth-child(4) {
      white-space: nowrap;
      flex: 0 0 auto;
      width: 216px;
    }

    td:last-child {
      text-align: center;
      text-align: -webkit-center;
      flex: 0 0 auto;
      width: 218px;
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
      td:first-child {
        border-bottom-left-radius: var(--spacing-1);
      }
      td:last-child {
        border-bottom-right-radius: var(--spacing-1);
      }
    }

    .title-container {
      //contains title and icon
      display: flex;
      gap: 12px;
      max-width: 100%;
      overflow: hidden;
      color: var(--white);

      div {
        max-width: 100%;
        overflow: hidden;
      }

      div:first-child {
        //icon only
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
      }
    }
  }

  .uploaded-by-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    background: var(--gray-900);
    border-radius: 50%;
    color: var(--white);
    text-align: center;
    font-size: var(--font-size-body-sm);
    font-weight: var(--font-weight-extra-bold);
  }

  .uploaded-by-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th,
  td {
    padding: 12px var(--spacing-3);
    border-bottom: 1px solid var(--gray-700);
    color: var(--gray-200);
    background: rgba(117, 117, 117, 0.15);
    font-size: var(--font-size-body-sm);
    font-weight: var(--font-weight-extra-bold);
    line-height: 20px;
    word-wrap: break-word;
  }

  td {
    padding: var(--spacing-2) var(--spacing-3);
    color: var(--gray-300);
    font-size: var(--font-size-body-md);
    font-weight: var(--font-weight-light);
    display: flex;
    align-items: center;

    .container {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      color: red;
      position: relative;
    }
  }
}

.dropdown-button {
  @include btn-reset-styles;
  height: 34px;
  width: 34px;
  color: var(--white);
  padding: var(--spacing-0);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-700);
    border-radius: 50%;
  }
}

.dropdown-menu {
  position: absolute;
  top: 110%;
  left: -10%;
  font-size: var(--font-size-body-md);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid var(--gray-700);
  background-color: var(--gray-900);
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-1) var(--spacing-0);
  gap: 4px;
  border-radius: var(--spacing-1);
  align-items: flex-start;
  width: max-content;

  button {
    @include btn-reset-styles();
    color: var(--white);
    background-color: var(--gray-900);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: var(--spacing-1) var(--spacing-3) var(--spacing-1) var(--spacing-2);
    font-size: var(--font-size-body-md);
    width: 100%;

    button > div {
      height: var(--spacing-3);
      width: var(--spacing-3);
    }

    &:hover {
      background-color: var(--gray-800);
    }
  }
}

.error-message {
  margin-bottom: var(--spacing-2);
  background-color: transparent;
  padding-bottom: var(--spacing-1);
  border-radius: var(--spacing-1);
  color: red;
  display: flex;
  justify-content: center;
}
