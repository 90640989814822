@import '../../../styles/variables.breakpoints.module.scss';

// @TODO: needs refactor, was hacked up
.divider {
  display: block;
  border-top-color: var(--gray-300);
  border-bottom-color: transparent;

  &.divider--no-rule {
    // can't use border-bottom-color: transparent due to artifact in iOS
    visibility: hidden;
  }

  &.divider--height-1 {
    margin: var(--spacing-2) 0;
  }

  // used in careers page
  &.divider--height-2 {
    margin: var(--spacing-3) 0;

    @include breakpoint-up(xl) {
      margin: var(--spacing-4) 0;
    }
  }

  // used in about and partnership pages
  &.divider--height-3 {
    margin: var(--spacing-2) 0;

    @include breakpoint-up(md) {
      margin: var(--spacing-3) 0;
    }

    @include breakpoint-up(xl) {
      margin: var(--spacing-5) 0;
    }
  }
}
