@import '../styles/variables.breakpoints.module.scss';
@import '../styles/globals.slickSlider.scss';
@import '../styles/globals.reactFormstack.scss';
@import '../styles/mixins.scss';

*,
::after,
::before {
  box-sizing: border-box;
}

* {
  font-family: var(--font-family-default); // font-family applied to body does not work for buttons and input[type=submit]
}

html,
body {
  position: relative;
  height: auto;
  padding: 0;
  margin: 0;
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-body);
  line-height: var(--line-height-body);
  background-color: var(--body-bg-color);
  color: var(--body-color);

  // Prevent adjustments of font size after orientation changes in iOS
  -webkit-text-size-adjust: 100%;

  // Font smoothing
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

main,
#__next {
  height: 100%;
}

#root-modal + div > main {
  //Prevents a z-index bug in Safari 16/Mac 13 that hides the entire gallery
  -webkit-transform: translate3d(0, 0, 0);
}

/* fixes next.js responsive image issue */
.image-container {
  position: relative;
  width: 100%;

  span {
    position: unset !important;
    padding-top: 0 !important;
  }

  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

/*
 * Typography
 */
p,
small {
  margin-top: 0;
  display: block;
}

p {
  margin-bottom: 15px;
  line-height: 1.5;
  color: var(--white);
}

small {
  @include body-copy(xsm);
  margin-bottom: var(--spacing-1);
  line-height: 18px;
}

strong {
  font-weight: var(--font-weight-semi-bold);
}

/*
 * Links
 */
a {
  @include link(brand-primary, false);
}

/*
 * Layout utilities
 * !importants for .container required to override inline styles from react-grid-system
 */
.container {
  // @TODO: look into why container width is not being passed to Container component
  width: calc(1128px - 48px);

  z-index: 5;

  &.container--width-100 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  &.container--width-100-md-down {
    @include breakpoint-down(md) {
      max-width: 100% !important;
    }
  }

  &.container--width-100-xl-up {
    @include breakpoint-up(xl) {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

.flex-row {
  @include flex-row();

  .flex-col-width-auto {
    @include flex-col();
  }
}

.align-contents-vertical {
  display: flex;
  align-items: center !important; // required to override inline style from react-grid-system
}

.align-content-bottom {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.justify-center,
.justify-around {
  text-align: center;
}

.justify-left {
  text-align: left;
}

.justify-right,
.justify-end {
  text-align: right;
}

.font-size-lg {
  @include body-copy(lg);

  p {
    @include body-copy(lg);
  }
}

.font-size-regular, // legacy class, update schema
.font-size-md {
  @include body-copy(md);

  p {
    @include body-copy(md);
  }
}

.font-size-small, // legacy class, update schema
.font-size-sm {
  @include body-copy(sm);

  p {
    @include body-copy(sm);
  }
}

.font-size-xsm {
  @include body-copy(xsm);

  p {
    @include body-copy(xsm);
  }
}

.is-black {
  color: var(--black);
}

.full-width {
  width: 100%;
}

.margin-top-auto {
  margin-top: auto;
}

.padding-y-0-lg {
  @include breakpoint-up(lg) {
    // !important required to override inline style from react-grid-system package
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.content-fade-in {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transition-delay: 0.75s;
}

.has-content-fade-in {
  opacity: 1;
}

.overflow-visible {
  overflow: visible !important;
}

hr {
  &.divider {
    border: 0.2px solid var(--black);
    opacity: 0.25;
  }
}

/*
 *  Moving away from defining margins in the Heading component
 * @TODO: requires refactoring to get away from !important. Perhaps create a HeadingGroup component
 */

// when an eyebrows are stacked with headings
h4 + h2,
h5 + h3,
h4 + h3 {
  margin-top: 15px !important;
}

h1 + h2,
h1 + h3 {
  margin-top: 0 !important;
}

h2 + button,
h2 + .paragraph-container {
  margin-top: -30px;

  @include breakpoint-up(xl) {
    margin-top: -60px;
  }
}
.panel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}

.panel {
  height: 100vh;
  position: sticky;
  top: 0;
}
