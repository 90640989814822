@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.button {
  background-color: transparent;
  border-radius: var(--spacing-5);
  border: 1px solid var(--gray-100);
  color: var(--gray-100);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family-default);
  font-size: var(--font-size-h4-mobile);
  font-weight: var(--font-weight-light);
  justify-content: center;
  line-height: 21.6px;
  padding: var(--spacing-1) var(--spacing-3);
  margin: 0 auto;
  margin-top: var(--spacing-3);
  text-align: center;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: rgba(226, 226, 226, 0.1);
  }

  @include breakpoint-up('md') {
    width: 157px;
    margin-top: var(--spacing-6);
  }
}

.container {
  padding: var(--spacing-5) var(--spacing-2);
  background-image: url('/assets/content/partnership_landing_form section_1920.png');
  background-position: top;

  @include breakpoint-up('md') {
    padding: var(--spacing-7) var(--spacing-3);
  }
}

.video-container {
  width: 100%;
}

// overwriting react-slider dots
.tiles {
  bottom: -17px !important;

  li {
    button {
      height: 6px !important;
      width: 6px !important;
    }
  }
}

.heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: 0 auto;
  padding-bottom: var(--spacing-2);
  padding-top: 50px;
  text-align: center;
  width: 288px;

  @include breakpoint-up('md') {
    width: 100%;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 57.6px;
    padding-bottom: var(--spacing-1);
  }
}

.subheading {
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-light);
  text-align: center;
  padding-bottom: var(--spacing-4);
  line-height: 20px;

  @include breakpoint-up('md') {
    font-size: 28px;
    font-style: normal;
    font-weight: var(--font-weight-extra-bold);
    line-height: 33.6px;
    padding-bottom: var(--spacing-5);
  }
}
