@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.pagination-container {
  padding-bottom: var(--spacing-8);

  .page-link {
    cursor: pointer;
    border-radius: 50%;
    border-color: var(--gray-300);
    border-style: solid;
    border-width: 2px;
    width: 48px;
    height: 48px;
    background-color: transparent;
    color: var(--white);

    //Styling that applies to the Galaxy Fold only
    @include breakpoint-down(xxs) {
      width: 40px;
      height: 40px;
    }

    &.page-link--selected,
    &:hover {
      background-color: var(--gray-300);
      color: var(--black);
      border-color: var(--gray-300);
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 0;
    margin: 0;

    //Styling that applies to the Galaxy Fold only
    @include breakpoint-down(xxs) {
      gap: 10px;
    }
  }

  li {
    display: inline-block;
  }
}
