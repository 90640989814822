/* 
 * @TODO: Move this css to Carousel.module.scss. 
 * Currently only works from this file.
 */
@import '../styles/variables.breakpoints.module.scss';
@import '../styles/mixins.scss';

.slick-slider {
  position: relative;
  display: block;
  width: 100%; // for homepage carousel
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border-radius: 12px;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  min-height: 1px;

  > div {
    height: 100%;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-disabled,
.slick-arrow.slick-hidden {
  opacity: 0;
}

/* 
 * Our custom slider css
 */
.slick-slide {
  position: relative;
}

.slide-overflow-is-visible {
  margin: 24px 0 40px 0;

  .slick-list {
    overflow: visible;

    .slick-track {
      max-height: 100%;
    }
  }

  .slick-slide {
    opacity: 0.6;
    padding: 0 4px;

    @include breakpoint-up(md) {
      padding: 0 8px;
    }

    p {
      min-height: 35px; // hack to resolve jumps in height. making slides equal height requires more investigation.
      margin-top: 8px;
      margin-bottom: 0;
      font-size: var(--font-size-body-xsm);

      @include breakpoint-up(sm) {
        min-height: 48px;
        font-size: var(--font-size-body-md);
      }
    }

    &.slick-active {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

.is-thumbnail-nav {
  .slick-slide {
    img {
      border: 2px solid transparent;
      border-radius: 8px;
      opacity: 0.4;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      @include breakpoint-up(md) {
        border-radius: 16px;
      }
    }

    .is-active-thumb-nav img {
      border-color: var(--white);
      opacity: 1;
    }
  }
}

ul.slick-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -40px;
  padding-left: 0;
  margin: 0;

  &.slick-top {
    top: -55px;
  }

  li button {
    height: 13px;
    width: 13px;
    padding: 0;
    background-color: transparent;
    border: 1px solid var(--gray-500);
    border-radius: 50%;
    text-indent: -9999px;
  }
}

.slick-dots li {
  list-style: none;
  display: inline;
  margin: 0 5px;
}

.is-full-screen .slick-dots {
  right: 30px;
  top: 50%;
  width: auto;
}

.is-full-screen .slick-dots li {
  display: block;
}

.slick-dots .slick-active button {
  background: var(--gray-500);
}

.slick-arrow {
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  transition: border-color 1s;
  width: 56px;
  height: 56px;
  background-color: rgba(var(--black-rgba), 0.3);
  text-indent: -99999px;
  border: 2px solid rgba(var(--white-rgba), 0.2);
  backdrop-filter: blur(25px);
  border-radius: 50%;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;

  @include breakpoint-up(lg) {
    width: 92px;
    height: 92px;
  }

  &:hover {
    transition: border-color 0.5s;
    border-color: rgba(var(--white-rgba), 0.25);
  }

  &:before {
    top: 12px;

    @include breakpoint-up(lg) {
      top: 24px;
    }
  }

  &:after {
    top: 23px;

    @include breakpoint-up(lg) {
      top: 40px;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 16px;
    background-color: var(--white);

    @include breakpoint-up(lg) {
      height: 24px;
      width: 2px;
    }
  }
}

.slick-arrow.slick-prev {
  left: -30px;

  &:after,
  &:before {
    left: 24px;

    @include breakpoint-up(lg) {
      left: 40px;
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }
}

.slick-arrow.slick-next {
  right: -30px;

  &:after,
  &:before {
    left: 28px;

    @include breakpoint-up(lg) {
      left: 45px;
    }
  }

  &:before {
    transform: rotate(135deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}
