@import '../../../styles/mixins.scss';
@import '../../../styles/variables.breakpoints.module.scss';

.btn-lg-wrapper {
  width: 100%;
  margin: var(--spacing-1) auto;

  &.btn-lg-wrapper--justify-contents-center {
    text-align: center;
  }

  &.btn-lg-wrapper--justify-contents-left {
    text-align: left;
  }
}

.btn {
  &.btn--primary {
    &.btn--fill-solid {
      &.btn--xl {
        @include btn(xl, primary, solid);
      }
      &.btn--lg {
        @include btn(lg, primary, solid);
      }
      &.btn--md {
        @include btn(md, primary, solid);
      }
      &.btn--sm {
        @include btn(sm, primary, solid);
      }
    }

    &.btn--fill-hollow {
      &.btn--xl {
        @include btn(xl, primary, hollow);
      }
      &.btn--lg {
        @include btn(lg, primary, hollow);
      }
      &.btn--md {
        @include btn(md, primary, hollow);
      }
      &.btn--sm {
        @include btn(sm, primary, hollow);
      }
    }

    &.btn--fill-text {
      &.btn--xl {
        @include btn(xl, primary, text);
      }
      &.btn--lg {
        @include btn(lg, primary, text);
      }
      &.btn--md {
        @include btn(md, primary, text);
      }
      &.btn--sm {
        @include btn(sm, primary, text);
      }
    }

    &.btn--fill-square {
      &.btn--xl {
        @include btn(
          sxteen,
          primary,
          square,
          $border-radius: 0px,
          $custom-size: true,
          $height: var(--header-height-desktop),
          $width: 166px,
          $mobile-height: var(--header-height-mobile),
          $mobile-width: 66px
        );
      }
    }
  }

  &.btn--secondary {
    &.btn--fill-solid {
      &.btn--xl {
        @include btn(xl, secondary, solid);
      }
      &.btn--lg {
        @include btn(lg, secondary, solid);
      }
      &.btn--md {
        @include btn(md, secondary, solid);
      }
      &.btn--sm {
        @include btn(sm, secondary, solid);
      }
    }

    &.btn--fill-hollow {
      &.btn--xl {
        @include btn(xl, secondary, hollow);
      }
      &.btn--lg {
        @include btn(lg, secondary, hollow);
      }
      &.btn--md {
        @include btn(md, secondary, hollow);
      }
      &.btn--sm {
        @include btn(sm, secondary, hollow);
      }
    }

    &.btn--fill-text {
      &.btn--xl {
        @include btn(xl, secondary, text);
      }
      &.btn--lg {
        @include btn(lg, secondary, text);
      }
      &.btn--md {
        @include btn(md, secondary, text);
      }
      &.btn--sm {
        @include btn(sm, secondary, text);
      }
    }
  }

  &.btn--light {
    &.btn--fill-solid {
      &.btn--xl {
        @include btn(xl, light, solid);
      }
      &.btn--lg {
        @include btn(lg, light, solid);
      }
      &.btn--md {
        @include btn(md, light, solid);
      }
      &.btn--sm {
        @include btn(sm, light, solid);
      }
    }

    &.btn--fill-hollow {
      &.btn--xl {
        @include btn(xl, light, hollow);
      }
      &.btn--lg {
        @include btn(lg, light, hollow);
      }
      &.btn--md {
        @include btn(md, light, hollow);
      }
      &.btn--sm {
        @include btn(sm, light, hollow);
      }
    }

    &.btn--fill-text {
      &.btn--xl {
        @include btn(xl, light, text);
      }
      &.btn--lg {
        @include btn(lg, light, text);
      }
      &.btn--md {
        @include btn(md, light, text);
      }
      &.btn--sm {
        @include btn(sm, light, text);
      }
    }
  }

  &.btn--dark {
    &.btn--fill-solid {
      &.btn--xl {
        @include btn(xl, dark, solid);
      }
      &.btn--lg {
        @include btn(lg, dark, solid);
      }
      &.btn--md {
        @include btn(md, dark, solid);
      }
      &.btn--sm {
        @include btn(sm, dark, solid);
      }
    }

    &.btn--fill-hollow {
      &.btn--xl {
        @include btn(xl, dark, hollow);
      }
      &.btn--lg {
        @include btn(lg, dark, hollow);
      }
      &.btn--md {
        @include btn(md, dark, hollow);
      }
      &.btn--sm {
        @include btn(sm, dark, hollow);
      }
    }

    &.btn--fill-text {
      &.btn--xl {
        @include btn(xl, dark, text);
      }
      &.btn--lg {
        @include btn(lg, dark, text);
      }
      &.btn--md {
        @include btn(md, dark, text);
      }
      &.btn--sm {
        @include btn(sm, dark, text);
      }
    }
  }

  &.btn--is-full-width {
    @include btnFullWidth();
  }

  &.btn--justify-center {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &.btn--justify-right {
    margin-left: auto;
  }

  &.btn--is-disabled {
    &.btn--fill-solid,
    &.btn--fill-hollow {
      background: var(--gray-600) !important;
      color: var(--gray-300) !important;
      border-color: transparent !important;

      &:before,
      &:hover {
        background: var(--gray-600) !important;
        color: var(--gray-300) !important;
        border-color: transparent !important;
        box-shadow: none;
      }
    }

    &.btn--fill-text {
      color: var(--gray-600) !important;
    }
  }
}

.hide-title-on-mobile .btn-title {
  display: none;
  @include breakpoint-up(md) {
    display: inline-block;
  }
}

.button-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  white-space: normal;

  @include breakpoint-up(md) {
    flex-direction: row;
    white-space: nowrap;
  }
}
