.render-client-container {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .video-container {
    width: 100%;

    .video-player {
      video {
        height: 100vh;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .video-metadata-filter-container {
    position: absolute;
    top: 23px;
    display: flex;
    gap: 20px;
    left: 17%;
  }

  .loading__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--gray-900);
  }
}
