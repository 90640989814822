@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.preview-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-3);
  gap: 4px;
  width: 124px;
  height: 34px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  /* Art/Art 500 */
  border: 1px solid var(--turquoise-500);
  border-radius: 40px;

  /* Misc./Buttons/Medium */
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-body-sm);
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.05em;

  /* Neutral/White */
  color: var(--white);

  svg {
    fill: var(--white);
    height: 18px;
    width: 18px;
  }

  &:hover {
    cursor: pointer;
    background: linear-gradient(251.02deg, var(--turquoise-500) 15.2%, var(--turquoise-300) 70.17%);
    color: var(--black);
    svg {
      fill: var(--black);
    }
  }
}
