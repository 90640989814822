@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.social-icons {
  ul {
    &.is-aligned-center {
      text-align: center;
    }

    li {
      display: inline-block;
      margin: 0 8px;

      @include breakpoint-up(lg) {
        margin: 0 10px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 32px;
        height: 32px;

        @include breakpoint-up(lg) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
