@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  video::-webkit-media-controls-panel {
    display: none;
  }
}

.video-container:hover {
  video::-webkit-media-controls-panel {
    display: flex;
  }
}

// .poster-container {
//   position: relative;
//   width: 100%;

//   .modal-open-button {
//     width: 64px;
//     height: 64px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: var(--black);
//     border-radius: 50%;
//     border: 2px solid var(--brand-accent);
//     transition: opacity 0.3s;

//     .play-icon {
//       @include arrow(right);
//     }
//   }

//   &:hover {
//     .modal-open-button {
//       opacity: 0.85;
//     }
//   }
// }

.modal-container {
  background-color: var(--gray-900);
  border-radius: var(--spacing-1);
  padding: var(--spacing-2);

  h3 {
    font-size: 18px;
    font-weight: var(--font-weight-light);
    margin-bottom: var(--spacing-2);
    padding-right: var(--spacing-5);

    @include breakpoint-down(md) {
      font-size: var(--font-size-h5-mobile);
    }
  }
}
