@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--black-rgba), 0.8);

  .icon-close-container {
    @include btn-reset-styles();
    position: absolute;
    top: var(--spacing-2);
    right: var(--spacing-2);
    padding: var(--spacing-0);

    svg {
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    position: relative;
    padding: var(--spacing-5) var(--spacing-2) var(--spacing-3);
    width: 420px;
    background-color: var(--gray-900);
    backdrop-filter: blur(4px);
    border-radius: var(--spacing-2);
    text-align: center;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-3);

    .cta-panel {
      width: 100%;
      display: flex;
      gap: var(--spacing-2);

      button {
        height: 44px;
        width: 100%;
        border-radius: 40px;
        border: 0;
        border-color: transparent;
        font-size: 16px;
        color: var(--white);
        background-color: var(--gray-900);
        border: 1px solid #e2e3e4;
        transition: background 0.4s, border 0.2s;

        &:hover {
          color: var(--black);
          border: 1px solid transparent;
          background: linear-gradient(250deg, var(--white) 19%, var(--gray-200) 75%);
          cursor: pointer;
        }
      }

      button:not([data-type='secondary']):last-child {
        background: linear-gradient(250deg, var(--gray-200) 19%, var(--white) 75%);
        color: var(--black);

        &:hover {
          background: linear-gradient(250deg, var(--white) 19%, var(--gray-200) 75%);
          cursor: pointer;
        }
      }
    }
  }
}
