@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.gallery-container {
  width: 100%;
  position: relative;
  height: 100%;
  background-size: cover;
  padding-bottom: var(--spacing-10);
}

.loading__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--black);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
