.requirements-container {
  margin-bottom: var(--spacing-2);

  .requirements-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
    span[data-status='false'] {
      color: #f52500;
    }
  }
}
