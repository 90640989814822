@import '../../../styles/variables.breakpoints.module.scss';

.breadcrumbs {
  position: relative;
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-3);
  z-index: 10;
  font-size: var(--font-size-body-sm);
}

.breadcrumb-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  span {
    font-weight: normal;

    &:last-child {
      @include breakpoint-up('md') {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.breadcrumb-item {
  margin-right: var(--spacing-1);
  display: flex;
  align-items: center;
  color: var(--gray-100);

  &:not(:last-child):after {
    content: '/';
    margin-left: var(--spacing-1);
  }
}

.breadcrumb-link {
  text-decoration: none;
  color: var(--gray-100);
  display: flex;
  align-items: center;

  svg {
    margin-right: var(--spacing-1);
    height: 12px;
    width: 12px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus,
  &:active {
    outline: none;
    text-decoration: underline;

    @include breakpoint-down('md') {
      font-weight: normal;
    }
  }
}
