/* 
 * @TODO: Move react-slick css from styles/globals.scss to here.
 * Currently only works from App.module.css.
 */
@import '../../../styles/variables.breakpoints.module.scss';

.carousel-container {
  margin: var(--spacing-12) 0;
  background-image: url('/assets/content/exoBG.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  overflow-x: hidden;
}

.heading-container {
  margin: var(--spacing-12) var(--spacing-2) var(--spacing-6);

  @include breakpoint-up(md) {
    margin: var(--spacing-12) var(--spacing-6);
  }
}

.carousel-playlist {
  margin-top: -15px;

  @include breakpoint-up(sm) {
    margin-top: -25px;
  }

  @include breakpoint-up(md) {
    margin-top: -30px;
  }

  @include breakpoint-up(lg) {
    margin-top: -35px;
  }
}
