@import '../../../../styles/variables.breakpoints.module.scss';
@import '../../../../styles/mixins.scss';

.play-button {
  @include btn-reset-styles();
  position: absolute;
  bottom: var(--spacing-3);
  right: var(--spacing-3);
  width: var(--spacing-9);
  height: var(--spacing-9);
  border: 1px solid var(--white);
  border-radius: var(--spacing-5);
  background-color: var(--black);
  cursor: pointer;
  opacity: 0.6;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s linear;

  svg {
    animation: fade-in 0.3s ease-in-out;
  }

  &:hover {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
