@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-container--bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 290px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  @include breakpoint-up(sm) {
    height: 400px;
  }

  @include breakpoint-up(md) {
    height: 375px;
  }

  @include breakpoint-up(lg) {
    height: 350px;
  }

  @include breakpoint-up(xl) {
    height: 375px;
  }

  @include breakpoint-up(xxl) {
    height: 500px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: var(--spacing-3);
    min-width: 100%;
    background: var(--hero-fade-bottom-color);

    @include breakpoint-up(md) {
      height: var(--spacing-7);
    }

    @include breakpoint-up(lg) {
      height: 145px;
    }

    @include breakpoint-up(xl) {
      height: 185px;
    }
  }
}

.hero-container {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18rem;
  margin-bottom: 32px;

  @include breakpoint-up(xs) {
    height: 15rem;
  }

  @include breakpoint-up(sm) {
    height: 16rem;
  }

  @include breakpoint-up(md) {
    margin-bottom: 24px;
    height: 16rem;
  }
  @include breakpoint-up(lg) {
    margin-bottom: 40px;
    height: 17rem;
  }

  @include breakpoint-up(xxl) {
    margin-bottom: 100px;
  }

  .headline {
    h1 {
      text-align: center;
      padding-bottom: 3%;

      @include breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }

    p {
      text-align: center;
      padding: 0 5%;
      font-size: var(--font-size-body-sm);

      @include breakpoint-up(md) {
        padding: 0 4%;
        font-size: var(--font-size-body-md);
      }

      @include breakpoint-up(lg) {
        padding: 0 21%;
      }
    }
  }
}
