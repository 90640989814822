@import '../../../styles/variables.breakpoints.module.scss';

.dropdown-container {
  height: var(--header-height-desktop);
  display: inline-flex;
  border-left: 1px solid var(--gray-500);
  border-right: 1px solid var(--gray-500);
  border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #757575, rgba(0, 0, 0, 0)) 1 100%;
  background: rgba(0, 0, 0, 0.05);
  text-wrap: balance;

  @include breakpoint-up(lg) {
    position: relative;
  }

  button {
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-specialty-label-desktop);
    gap: var(--spacing-2);
    padding: var(--spacing-1) var(--spacing-3);

    &:focus {
      outline: none;
    }
  }

  .dropdown-menu {
    z-index: 2;
    padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 528px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.65) 100%);
    backdrop-filter: blur(12px);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-style: solid;
    border-image: linear-gradient(to bottom, rgba(117, 117, 117, 0), #757575, rgba(117, 117, 117, 0)) 1 100%;
    max-height: calc(100vh - 96px);
    overflow-y: auto;
    scrollbar-color: var(--gray-400) transparent;
    scrollbar-width: thin;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint-up(lg) {
      left: -1px;
      transform: unset;
    }

    &.time-of-day {
      @include breakpoint-up(lg) {
        left: calc(-100% - 3px); //Shifting the menu to the left the width of the button + 3 borders
      }

      @include breakpoint-up(xl) {
        left: -1px;
      }

      ul .group-thumbnails {
        padding-top: 16px;
        padding-bottom: 0;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        padding: 0;
        size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8);
      }

      .group-thumbnails {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(140, 140, 140, 0.66);
          padding-bottom: 16px;
        }

        .thumbnail {
          width: 160px;
          height: 100px;
          border-radius: 8px;
          cursor: pointer;
          position: relative;
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.8) 100%);

          img {
            object-fit: cover;
            border-radius: 8px;
          }

          .thumbnail-label {
            position: absolute;
            bottom: 6px;
            left: 6px;
            display: flex;
            flex-direction: row;
            gap: 6px;
            font-size: 12px;
            align-items: center;
            z-index: 5;

            span {
              align-self: center;
            }
          }

          &.selected {
            outline: 3px solid rgba(255, 255, 255, 0.5);
          }

          &:hover {
            outline: 3px solid rgba(255, 255, 255, 1);
          }

          svg {
            min-width: var(--spacing-3);
          }
        }
      }

      hr {
        margin: var(--spacing-1) 0;
        border: 1px solid var(--gray-500);
        color: white;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

.item-display-name {
  display: none;

  @include breakpoint-up(xl) {
    display: block;
  }
}
