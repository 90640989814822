@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}

.default-border {
  border: 2px dashed var(--turquoise-300);
}

.upload-border {
  background-color: black;

  &:after {
    --borderWidth: 2px;
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: conic-gradient(from var(--bg-angle), rgb(55 255 255), rgb(0 2 2));
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: spin 3s reverse infinite;
    background-size: 100% 100%;
  }
}

.done-border {
  border: 2px solid green;

  @keyframes fadeUpAndDisappear {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  animation: fadeUpAndDisappear 1s ease-out 2s forwards;
}

.box {
  margin-top: var(--spacing-5);
  min-height: 133px;
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .upload-controls {
    padding: var(--spacing-2) var(--spacing-3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .file-upload-error {
    padding: 36px 18px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--spacing-2);
    span {
      line-height: 21px;
    }
    > div {
      display: flex;
      gap: var(--spacing-2);
    }

    .file-info,
    .error-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      span:last-child {
        color: var(--gray-300);
      }
    }

    .error-info {
      text-align: right;
      span:first-child {
        color: #f52500;
      }
    }

    .error-info-icon {
      display: flex;
      align-items: center;
    }
  }

  .file-input-label {
    margin-top: 12px;
    text-align: center;
    font-size: var(--font-size-body-md);

    label {
      color: var(--turquoise-300);
      cursor: pointer;

      &:hover {
        color: var(--turquoise-500);
      }
    }
  }

  .file-upload-progress {
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.18);
  }

  .file-input {
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
  }

  .file-upload-icon {
    margin: 0 auto;
  }
}

.box.highlight {
  border-color: #5418d6;
}

.box.error {
  border: 1px solid #f52500;
}

.file-upload-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(184, 184, 184, 0.5);
}
