@import '../../../styles/variables.breakpoints.module.scss';

.container {
  margin: 0 auto;
  padding: var(--spacing-12);
  color: #ffffff;

  .heading {
    font-size: var(--font-size-h2-desktop);
    font-weight: var(--font-weight-semi-bold);
    line-height: 28px;
    text-align: center;
    padding-bottom: var(--spacing-1);

    @include breakpoint-up('md') {
      font-size: var(--font-size-h1-mobile);
      text-align: left;
      line-height: 38.4px;
      padding-bottom: var(--spacing-2);
    }
  }

  .faq-item {
    padding: var(--spacing-3) 0;
    cursor: pointer;
    border-bottom: 1px solid var(--gray-500);
    transition: height 0.4s linear;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      font-size: var(--font-size-h3-mobile);
      font-weight: var(--font-weight-light);
      line-height: 20px;
      margin: 0;

      @include breakpoint-up('md') {
        font-size: var(--font-size-h3-desktop);
        line-height: 26.4px;
      }

      .icon {
        transform-origin: center;
        transition: transform 0.4s linear;
      }

      .rotated {
        transform: rotate(-180deg);
      }
    }

    .answer {
      font-size: var(--font-size-h5-mobile);
      font-weight: var(--font-weight-light);
      line-height: 24px;
      padding-top: 0px;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.4s linear, opacity 0.4s linear, padding-top 0.3s linear;

      @include breakpoint-up('md') {
        font-size: var(--font-size-h4-mobile);
        line-height: 27px;
      }
    }

    .visible {
      padding-top: var(--spacing-3);
      max-height: 500px;
      opacity: 1;
    }
  }
}
