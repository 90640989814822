/* 
 * @TODO: Move this css to Form.module.scss. 
 * Currently only works from this file.
 */
@import '../styles/mixins.scss';
@import '../styles/variables.breakpoints.module.scss';

$form-elements-full-width: "input[type='text'], input[type='name'], input[type='email'], input[type='tel'], input[type='number'], input[type='name'], input[type='date'], input[type='datetime'], select, textarea";

.fs-form {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: var(--spacing-2);
  text-align: left;

  .fs-fieldset {
    position: relative;
  }

  fieldset.fs-fieldset,
  fieldset.fs-fieldset__section {
    margin-bottom: var(--spacing-2);
    border: none;
    padding: 0;
    color: var(--white);

    &.fs-fieldset--required label {
      &:after {
        content: '*';
        position: absolute;
        z-index: 1;
        right: -8px;
        top: 0;
        color: var(--white);
      }
    }
  }

  .fs-section {
    span {
      p {
        margin-bottom: 15px;

        strong {
          @include heading(3);
        }

        + p {
          margin-bottom: 0;
        }
      }
    }
  }

  #{$form-elements-full-width} {
    font-size: var(--font-size-body);
    width: 100%;
    padding: 0.8rem 1rem;
    display: block;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    outline: 1px solid transparent;
    transition: border-color 0.5s ease;

    &:focus {
      outline: 1px solid var(--white);
      border-color: var(--white) !important;
    }

    &::placeholder {
      font-size: var(--font-size-specialty-form-inputs-desktop);
      font-weight: var(--font-weight-light);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px transparent inset !important; // !important required
    }
  }

  &.fs-form--single-email-field {
    width: 100%;
    position: relative;
    margin: 0 auto;

    input[type='email'] {
      padding-right: 85px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.formstack--input-bg-color-black {
    #{$form-elements-full-width} {
      background-color: var(--black) !important;
      color: var(--white);
      border-color: var(--gray-500);

      &:focus {
        border-color: var(--white);
      }

      &::placeholder {
        color: var(--gray-300);
      }

      // Prevent webkit from changing input color after autofill
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--black) inset;
        -webkit-text-fill-color: var(--white);
      }
    }

    // Make webkit datetime calendar icon white
    .fs-fieldset__datetime {
      ::-webkit-calendar-picker-indicator {
        filter: invert(0.7);
      }
    }
  }

  &.formstack--input-bg-color-white {
    #{$form-elements-full-width} {
      background-color: var(--white);
      color: var(--black);

      &::placeholder {
        color: var(--gray-300);
      }
    }
  }

  &.formstack--input-bg-color-transparent {
    #{$form-elements-full-width} {
      background: transparent;
      color: var(--white);
      border-color: var(--gray-500);

      &::placeholder {
        color: var(--gray-300);
      }

      // prevent webkit from changing input color after autofill
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--brand-primary) inset;
        -webkit-text-fill-color: var(--black);
      }
    }

    .fs-fieldset__datetime {
      //keeps input same height as other inputs on mobile
      input[type='date'] {
        min-height: 36px;
      }
      ::-webkit-calendar-picker-indicator {
        filter: invert(0.7);
      }
    }
  }

  fieldset.fs-fieldset {
    label {
      display: inline-block;
      margin-bottom: 3px;

      &.fs-label--hidden {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        transition: all 0.2s;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        cursor: text;
        color: var(--gray-300);

        &.fs-label--required {
          &:after {
            color: var(--gray-300);
          }
        }

        &.fs-label--input-is-in-focus-or-has-value {
          top: -1px;
          left: 16px;
          display: block;
          padding: 0 5px;
          font-size: var(--font-size-body-xsm);
          color: var(--white);
          background-color: var(--black);

          &.fs-label--required {
            &:after {
              right: -4px;
              padding-right: 2px;
              color: var(--white);
              background-color: rgba(var(--black-rgba), 0.8);
            }
          }
        }
      }
    }
  }

  .fs-fieldset__textarea {
    label.fs-label--hidden {
      display: none !important;
    }

    textarea {
      min-height: 160px;
    }
  }

  .fs-fieldset__select {
    .fs-field-wrapper {
      position: relative;
      display: block;

      &:after,
      &:before {
        content: '';
        position: absolute;
        bottom: 15px;
        width: 1px;
        height: 10px;
        background-color: var(--gray-700);
      }

      &:before {
        right: 28px;
        transform: rotate(140deg);
      }

      &:after {
        right: 22px;
        transform: rotate(40deg);
      }
    }

    select {
      appearance: none; // hide native browser caret
      cursor: pointer;
    }
  }

  .fs-fieldset__checkbox,
  .fs-fieldset__radio {
    ul {
      margin-bottom: 0;
      margin-top: 7px;
      padding-left: 0;
      list-style: none;
      width: 100%;

      li {
        width: 100%;
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: flex-start;
        margin-right: var(--spacing-1);

        @include breakpoint-up(sm) {
          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }

      label {
        margin-left: 10px;
        text-transform: none;

        &:after {
          content: none !important;
        }
      }
    }
  }

  .fs-fieldset__checkbox {
    input {
      &:after {
        /* @TODO: customize checkboxes */
      }
    }
  }

  .fs-fieldset__name {
    legend {
      display: none;
    }

    #fs-subfield-name-last {
      @include breakpoint-up(md) {
        //margin-right: var(--spacing-1);
        margin-top: var(--spacing-1);
      }
    }
    //commented out to make single line fields per designs
    // #fs-subfield-name-first,
    // #fs-subfield-name-last {
    //   @include breakpoint-up(md) {
    //     position: relative;
    //     width: calc(50% - var(--spacing-1) / 2);
    //     display: inline-block;
    //   }
    // }
  }

  @include breakpoint-down(sm) {
    .fs-field__subfield + .fs-field__subfield {
      margin-top: var(--spacing-3);
    }
  }

  // @TODO: figure out a cleaner way to style the option-in checkbox above submit button
  // Perhaps pass a class from Formstack?
  .fs-fieldset__checkbox:last-of-type > .fs-label--hidden + .fs-field-wrapper {
    p {
      @include body-copy(xsm);
    }

    ul {
      width: 100%;

      li {
        width: 100%;
      }

      label {
        display: inline;
      }
    }
  }

  button[type='submit'] {
    margin-top: var(--spacing-2);
  }

  &.formstack--submit-btn-bg-white {
    button[type='submit'] {
      @include btn(xl, light, solid);

      @include breakpoint-down(sm) {
        @include btnFullWidth();
      }
    }
  }

  &.formstack--submit-btn-bg-transparent {
    button[type='submit'] {
      @include btn(xl, light, hollow);
    }
  }

  .fs-section {
    // h2 comes from formstack
    h2 {
      @include heading(3);
      margin-top: 0;
      margin-bottom: var(--spacing-3);
    }

    p {
      margin-bottom: 0;
    }
  }
}
