@import '../../../styles/variables.breakpoints.module.scss';

.form-block {
  padding: 80px 0;

  @include breakpoint-up(lg) {
    padding-top: 112px;
  }

  h2 {
    margin-top: 0;
  }

  h3 {
    margin-top: 16px;
    margin-bottom: 32px;

    @include breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  small {
    color: var(--gray-300);
  }
}

.form-success {
  width: 54px;
  max-width: 54px;
  height: 54px;
  max-height: 54px;
  margin: 0 auto 16px auto;
}

.form-modal-wrapper {
  background-color: var(--gray-900);
  border-radius: var(--spacing-1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: var(--spacing-2);
  text-align: center;
  width: 15rem;
}
