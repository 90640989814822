@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-global {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  z-index: 9000;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: visible;
  background-color: transparent;
  backdrop-filter: blur(24px);
  transition: background-color 0.2s ease;
  justify-content: space-between;

  .header-global__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &.header-global__simple {
    background-color: transparent;

    &.header-global__video-player {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.05) 100%);
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
      padding: 0 var(--spacing-1);
    }
  }

  .user-info-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 4px;

    .user-name-email-container {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      span {
        display: block;
        max-width: 225px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .nav-container {
    display: flex;
    margin-left: auto;
    justify-items: flex-end;
    padding-left: 8px;

    .ctas {
      display: flex;
      margin-left: 10px;

      a {
        color: var(--white);
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-body-sm);
        align-items: center;
        justify-content: flex-start;
        margin-left: var(--spacing-2);

        @include breakpoint-down(sm) {
          align-items: end;
        }

        svg {
          margin-right: var(--spacing-1);
        }
      }

      .cta:nth-of-type(2) {
        margin-left: 25px;
      }
    }
  }

  .logout-cta {
    height: var(--spacing-5);
    width: var(--spacing-5);
    border-radius: var(--spacing-5);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    color: var(--white);
    border: none;
    box-shadow: 2px 2px 15px #05c3dd, -2px -2px 15px #05c3dd;
    transition: background 0.4s;
    flex: 0 0 auto;
    &:hover {
      background-color: var(--white);
      color: var(--black);
      cursor: pointer;
    }
  }

  .logout-container {
    border-top: 1px solid gray;
    padding: 0px;

    .logout {
      color: var(--white);
      padding: 12px var(--spacing-2);
      gap: var(--spacing-1);
      display: flex;
      align-items: center;
      height: 42px;
      width: 100%;
      background-color: var(--black);
      border: none;
      transition: background 0.4s;
      font-size: var(--font-size-body-md);
      font-weight: var(--font-weight-light);

      &:hover {
        cursor: pointer;
        background-color: var(--gray-600);
      }

      svg {
        height: var(--spacing-2);
        width: var(--spacing-2);
        color: var(--white);
        margin: 2px;
      }
    }
  }

  li {
    list-style: none;

    a,
    span {
      font-weight: var(--font-weight-light);
    }
  }

  .brand-logo {
    display: flex;
    align-items: flex-start;
    width: var(--header-brand-logo-width-mobile);
    min-width: var(--header-brand-logo-width-mobile);
    height: var(--header-brand-logo-height-mobile);
    min-height: var(--header-brand-logo-height-mobile);
    margin-right: 24px;
    flex: 0 0 auto;

    @include breakpoint-up(md) {
      width: var(--header-brand-logo-width-desktop);
      min-width: var(--header-brand-logo-width-desktop);
      height: var(--header-brand-logo-height-desktop);
      min-height: var(--header-brand-logo-height-desktop);
    }

    a {
      display: flex;
    }
  }

  .brand-logo-alt {
    margin: 0;
    width: var(--header-brand-logo-width-mobile);
    min-width: var(--header-brand-logo-width-mobile);
    height: var(--header-brand-logo-height-mobile);
    min-height: var(--header-brand-logo-height-mobile);
  }

  .exit-preview-button {
    @include btn-reset-styles();
    border: none;
    background: transparent;
    padding: var(--spacing-0);

    &:hover {
      cursor: pointer;
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    .header-global__column {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 24px;

      &.header-global__video-player {
        gap: var(--spacing-3);

        @include breakpoint-down(sm) {
          gap: var(--spacing-2);
        }

        .brand-logo {
          margin: 0;
          width: var(--header-brand-logo-width-desktop);
          min-width: var(--header-brand-logo-width-desktop);
          height: var(--header-brand-logo-height-desktop);
          min-height: var(--header-brand-logo-height-desktop);
        }

        .brand-logo-alt {
          margin: 0;
          width: var(--spacing-4);
          min-width: var(--spacing-4);
          height: var(--spacing-3);
          min-height: var(--spacing-3);
        }

        .button-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
        }
      }
    }

    .header-global__simple {
      justify-content: flex-start;
    }

    nav {
      color: var(--white);
      transition: height 0.1s ease-out;

      > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;
        text-align: center;

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 22.5px;
          font-weight: var(--font-weight-light);
          border: none;
          position: static;

          &:first-child {
            padding-left: 0;
          }

          ul.submenu {
            position: fixed;
            left: 0;
            top: var(--header-height-desktop);
            bottom: calc(var(--headerSubmenuHeightDesktop) * -1);
            display: flex;
            align-items: center;
            justify-content: center;
            height: var(--header-submenu-height-desktop);
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            border-top: 1px solid var(--gray-900);
            border-bottom: 1px solid var(--gray-900);
            background: var(--header-subnav-bg-color);
            backdrop-filter: blur(25px);

            li {
              align-items: center;
              border-bottom: 3px solid transparent;

              a {
                text-transform: none;

                &:hover {
                  color: var(--white);
                }
              }

              &.is-current-page {
                border-color: var(--brand-primary);

                a {
                  color: var(--white);
                }
              }
            }
          }

          .menu-item-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 var(--spacing-1);
            cursor: pointer;
            display: block;
            padding: 0;
            white-space: nowrap;

            .is-active-link {
              font-weight: var(--font-weight-semi-bold);

              &:hover {
                color: var(--white);
              }
            }

            .caret {
              position: absolute;
              left: 50%;
              top: 33px;
              transform: translate(-50%, -50%);

              svg {
                opacity: 50%;
                height: 10px;
                width: 10px;
              }
            }

            a,
            span {
              @include link(gray-100);
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.header-global--mobile {
    height: var(--header-height-mobile);

    .header-global__column {
      display: flex;
      height: 32px; // @TODO: set to global var to share with icon height
      align-items: center;
      width: 100%;
      padding-left: 16px;

      .ctas {
        a {
          margin-left: 8px;
        }
        button {
          margin-left: 25px;

          @include breakpoint-down(sm) {
            @include btnSize(sm);
          }

          @include breakpoint-down(xs) {
            margin-left: var(--spacing-1);
          }
        }
      }

      &.header-global__video-player {
        gap: var(--spacing-2);
        padding: 0 var(--spacing-2);

        .brand-logo-alt {
          margin: 0;
          width: var(--spacing-4);
          min-width: var(--spacing-4);
          height: var(--spacing-3);
          min-height: var(--spacing-3);
        }

        .button-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          flex: 0 0;
        }
      }
    }

    .nav-drawer-toggler {
      display: flex;
      align-items: center !important;
      margin-right: 16px;

      svg {
        @include svg-btn(white);
      }
    }

    .brand-logo {
      margin-right: auto;
    }

    &.drawer-open {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(12.5px);
      background: rgb(27, 27, 27, 0.75); //store in global variable

      // hide due to position shift
      .header-global__column,
      .header-global__column + .brand-logo {
        display: none;
      }
    }

    nav {
      position: absolute;
      top: 0;
      left: -320px;
      height: 100vh;
      width: 320px;
      z-index: 1000;
      background-color: var(--black);
      transition: height 0.1s ease-out;
      letter-spacing: 0.02em;
      max-width: 100%;
      transition: all 0.1s;

      &.is-open {
        left: 0;
      }

      .nav-drawer__header {
        display: flex;
        align-items: center;
        height: var(--header-height-mobile);
        padding: 0 16px;

        .nav-drawer-toggler {
          display: flex;

          svg {
            @include svg-btn(white);
          }
        }
      }

      .nav-drawer__scroller {
        height: calc(100% - var(--header-height-mobile));
        padding-bottom: 120px;
        overflow: auto;

        ul {
          width: 100%;
          padding: 0;
          margin: 0 0 40px 0;

          a {
            @include link(white);
          }

          li {
            padding: 28px 0;
            border-bottom: 1px solid var(--gray-700);
            font-size: var(--font-size-heading-sm-mobile);

            &:last-child {
              border: none;
            }

            &.is-open {
              .caret {
                transform: rotate(180deg);
              }
            }

            .menu-item-wrapper {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 0 var(--spacing-1);
              cursor: pointer;

              .caret svg {
                width: 13.5px;
              }
            }

            .submenu {
              padding: var(--spacing-1) var(--spacing-1) 0 var(--spacing-3);

              li {
                padding: 10px 0;
                border: none;
                text-transform: none;

                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        .nav-drawer__ctas {
          padding-right: 15px;
          padding-left: 15px;
          text-align: center;

          button {
            @include btnSize(lg);
            @include btnFullWidth();

            + button {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

.header-global.preview-page {
  border-bottom: 1px solid var(--gray-700);
}

.share-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: white;
  cursor: pointer;
  font-size: var(--font-size-specialty-label-desktop);
  gap: var(--spacing-2);
  padding: var(--spacing-1) var(--spacing-3);
  height: var(--header-height-desktop);
  display: inline-flex;
  border-left: 1px solid var(--gray-500);
  border-right: 1px solid var(--gray-500);
  border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #757575, rgba(0, 0, 0, 0)) 1 100%;
  background: rgba(0, 0, 0, 0.05);

  .item-display-name {
    display: none;

    @include breakpoint-up(xl) {
      display: block;
    }
  }
}
