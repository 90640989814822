@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.email-modal {
  width: 356px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  padding: var(--spacing-2) 24px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-3);

  h4 {
    color: var(--white);
    margin: 24px 0 5px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  p {
    font-size: 16px;
    color: var(--gray-100);
    text-align: center;
    margin: 0;
  }
}

.email-input {
  width: 288px;
  border: 1px solid var(--gray-600);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.8px;
  font-weight: 350;
  color: var(--white);
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  padding: 8px 16px;

  &:focus {
    border-color: var(--white);
    border-width: 2px;
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.cta-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.continue-button {
  padding: 8px 24px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  background: linear-gradient(251.02deg, #c6c6c6 15.2%, #ffffff 70.17%);
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 300;
  letter-spacing: 0.7px;
  width: 134px;
  height: 33px;
  border: 1px solid #e2e2e2;
  border-radius: 40px;

  &:hover {
    background-color: var(--blue-800);
  }
}
