@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.thumbnail-container {
  padding-bottom: var(--spacing-4);
  display: block;

  &:hover {
    cursor: pointer;
    img {
      opacity: 1;
    }
    h3 {
      color: var(--white);
    }
  }

  img {
    border-radius: var(--spacing-1);
    opacity: 1;
    transition: opacity 0.3s linear;
    aspect-ratio: 16/9;

    @media (hover: hover) {
      opacity: 0.6;
    }
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-weight: var(--font-weight-light);
    width: 100%;
    margin: var(--spacing-1) var(--spacing-0);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--gray-300);
    transition: color 0.3s linear;
    font-size: 18px;

    @include breakpoint-down(md) {
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: var(--font-size-h5-mobile);
    }
  }
}
